import { Typography } from '@grupo-sbf/motriz-core';
import { InfoCircle, Lightbulb } from '@grupo-sbf/motriz-icons/centauro';
import { useState } from 'react';
import TipsModal from '@/common/components/TipsModal/TipsModal';
import { useStrapi } from '@/common/hooks/useStrapi';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import {
  ContainerSubheader,
  Container,
  ContainerIcon,
  ContainerTitle,
  Icon,
  ButtonContainer,
  Tip,
  Button,
} from './Subheader.styled';
import { SubheaderProps } from './Subheader.types';

const Subheader = ({
  breadcrumbList,
  content,
  buttonPrimary,
  buttonSecondary,
}: SubheaderProps) => {
  const [openTipsModal, setOpenTipsModal] = useState(false);
  const { tips } = useStrapi();

  const tipIcon = (tips?.attributes?.tipIcon &&
    {
      lamp: <Lightbulb />,
      infoCircle: <InfoCircle />,
    }[tips.attributes.tipIcon]) || <InfoCircle />;

  return (
    <ContainerSubheader data-testid="container-subheader">
      {breadcrumbList && <Breadcrumb breadcrumb={breadcrumbList} />}
      <Container>
        <ContainerTitle>
          <ContainerIcon>
            <Icon>{content?.icon}</Icon>
            <Typography type="subtitle" size="large">
              {content?.title}
            </Typography>
            {tips && (
              <>
                <Tip
                  size="small"
                  icon={tipIcon}
                  variant="tertiary"
                  onClick={() => setOpenTipsModal(true)}
                >
                  {tips.attributes.tipText}
                </Tip>
                <TipsModal
                  isOpen={openTipsModal}
                  onClose={() => setOpenTipsModal(false)}
                  model={tips}
                />
              </>
            )}
          </ContainerIcon>
          {content?.description && (
            <Typography type="simple" mt={50}>
              {content?.description}
            </Typography>
          )}
        </ContainerTitle>
        <ButtonContainer>
          {buttonSecondary && (
            <Button
              onClick={buttonSecondary?.onClick}
              icon={buttonSecondary?.icon}
              disabled={buttonSecondary?.disabled}
              variant="secondary"
              size="medium"
              fullWidth
            >
              {buttonSecondary?.text}
            </Button>
          )}
          {buttonPrimary && (
            <Button
              onClick={buttonPrimary?.onClick}
              icon={buttonPrimary?.icon}
              disabled={buttonPrimary?.disabled}
              variant="primary"
              size="medium"
              fullWidth
            >
              {buttonPrimary?.text}
            </Button>
          )}
        </ButtonContainer>
      </Container>
    </ContainerSubheader>
  );
};

export default Subheader;

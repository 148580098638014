import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { getTips } from '@/services/strapiJsModule';
import { StrapiData, StrapiTips } from '../types/strapi';

export const useStrapi = (): StrapiData => {
  const { data: session } = useSession();
  const router = useRouter();
  const { pathname } = router;

  const { accessToken } = session || {};

  const [tips, setTips] = useState<StrapiTips>();

  useEffect(() => {
    (async () => {
      try {
        if (accessToken) {
          const tipsReq: StrapiTips[] = await getTips(accessToken, pathname);

          setTips(tipsReq[0]);
        }
      } catch {
        console.error('Failed to execute: useStrapiTips');
      }
    })();
  }, [accessToken, pathname]);

  return { tips };
};
